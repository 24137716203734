import { Edition, Property } from 'src/app/models/dwrm/base';
import { Upload } from 'src/app/models/dwrm/upload';
import { NotificationOriginName } from 'src/app/models/notification';
import { BaseModel, PaginatedResponse } from 'src/app/models/utils';

export const DOCUMENT_TYPES = [
  'DE_NT',
  'WE_NT',
  'SU_NT',
  'AN_RS',
  'INV',
] as const;

export enum DocumentTypes {
  DE_NT = 'Lieferschein',
  WE_NT = 'Wiegeschein',
  SU_NT = 'Begleitschein',
  AN_RS = 'Analyseblatt',
  INV = 'Rechnung',
}

export type DocumentType = keyof typeof DocumentTypes;

export interface DocumentData {
  id?: string;
  userId: string;
  companyId: string;
  disposerUserId: string;
  disposerCompanyId: string;
  orderId: string;
  uploadId?: string;
  type: string;
  number: string;
  properties: Property[];
  uploadedByDisposer?: boolean;
  payload?: {
    origin: NotificationOriginName;
    [key: string]: any;
  };
}

export interface Document extends BaseModel {
  userId: string;
  companyId: string;
  disposerUserId: string;
  disposerCompanyId: string;
  orderId: string;
  type: DocumentType;
  number: string;
  uploadIds: string[];
  uploads: Upload[];
  properties: Property[];
  editions: Edition[];
}

export type PaginatedDocuments = PaginatedResponse<Document[]>;

export interface MappedDocument extends Document {
  data: any;
}

/* SCHEMAS */

/* INVOICE
  data1: "Rechnungsdatum"
  data2: "Total EUR"
*/

/* DELIVERY_NOTE
  data1: "Lieferdatum"
*/

/* WEIGHT_NOTE
  data1: "Wiegescheinsdatum"
  data2: "Gesamtabfallmenge"
*/

/* SUPPLY_NOTE
  data1: "Abholdatum"
  data2: "Übergabedatum"
  data3: "Übergeber"
  data4: "Übernehmer"
  data5: "Gesamtabfallmenge"
  data_N: "Transporteuer_N"
*/

/* ANALYSTIC_RESULT
  data1: "Analysedatum"
  data2: "Bereitgestellt durch"
  data3: "Laborname"
  data4: "Laborstandort"
  data5: "GLN"
*/
